import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { HttpError, useCustom, useList, useTranslate, useApiUrl } from "@refinedev/core";
import { Box, Typography } from "@mui/material";
import { PedidoTimeline } from "../../components/dashboard/pedidoTimeline";
import { PedidosRecentes } from "../../components/dashboard/pedidosRecentes";
import { PieChart } from "../../components/charts/PieChart";
import {Card as CustomCard} from "../../components/card"
import { IPedido } from "../../interfaces";
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';


const totalRecordsPedido = (data: string | any[]):number=>{
    return data?.length
}

const totalRecordsAprovados = (data: any[]):number=>{
    return data?.filter(pedido => pedido.idStatus === 10).length
} 

const totalRecordsNAprovados = (data: any[]):number=>{
    return data?.filter(pedido => pedido.idStatus === 11).length
} 

const totalRecordsAndamento = (data: any[]):number=>{
    return data?.filter(pedido => [1,2,3,4,5,6,7,8,9].includes(pedido.idStatus)).length
} 


export const DashboardPage: React.FC = () => {
    const translate= useTranslate();
    const apiUrl = useApiUrl();
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');
    const aliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

    
    const { data:dataPedidoUsuario, isLoading: pedUsuIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/usuarios/${auth.id}`,
        method: "get",
      });


    const { data, isFetched, isError } = useList<IPedido, HttpError>({
        resource: "pedidos",
        pagination: {
            mode: "off",
          },
    });

    const pedidosUsuario =  dataPedidoUsuario?.data ?? [];  
    const arrayPedidosUsuario = pedidosUsuario.map((pedido: { id: any; }) => pedido.id);
    
    //const pedidos =  data?.data ?? [];
    const pedidos =  aliasPerfilAdmin.includes(auth.roles)? (data?.data?.filter(pedido => arrayPedidosUsuario.includes(pedido.id)) ?? []): (data?.data ?? []);
                

    return (
        <Grid container columns={24} spacing={2}>
            <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
                <Box>
                    <Typography variant="h5" fontWeight={400} sx={{ color: "text.primary", padding: "16px" }}>
                            {translate("pages.dashboard.title")}
                    </Typography>                    
                </Box>     
            </Grid>            
            <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
                {/* <Box>
                    <Typography variant="h5" fontWeight={400} sx={{ color: "text.primary", padding: "16px" }}>
                            {translate("pages.dashboard.title")}
                    </Typography>                    
                </Box>      */}
                {/* <Box>
                    <Typography variant="h5" fontWeight={700} sx={{ color: "text.primary" }}>
                        {translate("pages.dashboard.title")}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: "text.primary" }} mt={1}>
                        {translate("pages.dashboard.subtitle")}
                    </Typography>            
                </Box>                    */}  

                <Box mt="20px" display="flex" flexWrap="wrap" gap={4}>
                    <PieChart
                        title={translate("pages.dashboard.piechart01.title")}
                        value={isFetched?totalRecordsAndamento(pedidos):0}
                        series={[(isFetched?(totalRecordsAndamento(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsAndamento(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />
                    <PieChart
                        title={translate("pages.dashboard.piechart02.title")}
                        value={isFetched?totalRecordsAprovados(pedidos):0}
                        series={[(isFetched?(totalRecordsAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />
                    <PieChart
                        title={translate("pages.dashboard.piechart03.title")}
                        value={isFetched?totalRecordsNAprovados(pedidos):0}
                        series={[(isFetched?(totalRecordsNAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsNAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />
                    {/* <PieChart
                        title={translate("pages.dashboard.piechart04.title")}
                        value={555}
                        series={[75, 25]}
                        colors={["#be2e38", "#e5abaf"]}
                    /> */}
                </Box>                
            </Grid>
            <Grid item xs={24} md={16} xl={18}>
                {/* <Card sx={{ height: "100%", paddingX: { xs: 2 } }}>
                    <CardHeader title={translate("pages.dashboard.recentorders.title")} />
                    <PedidosRecentes />
                </Card> */}
                <CustomCard
                    icon={<EventNoteOutlinedIcon />}
                    title={translate("pages.dashboard.recentorders.title")}
                    cardContentProps={{
                    sx: {
                        height: "100%", paddingX: { xs: 2 }
                    },
                    }}
                > 
                    <PedidosRecentes />
                </CustomCard>    
            </Grid>
            <Grid item xs={24} md={8} xl={6}>
                {/* <Card
                    sx={{
                        paddingX: { xs: 2 },
                        fontSize: { xs: "16px" },
                    }}                
                > */}
                    {/* <CardHeader title={translate("pages.dashboard.timeline.title")}/> */}
                    {/* <PedidoTimeline />
                </Card> */}

                <CustomCard
                    icon={<WatchLaterOutlinedIcon />}
                    title={translate("pages.dashboard.timeline.title")}
                >
                    <PedidoTimeline />
                </CustomCard>
            </Grid>
         
        </Grid>



    );
};