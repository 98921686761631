import { useTranslate } from "@refinedev/core";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import type { ChipProps } from "@mui/material/Chip";

type StatusPedidoProps = {
    // status?: "digitado" | "aguardAprovGO" | "aguardAprovGGO" | "aguardAprovSuprim" | "aguardAprovGerSuprim" |
    //             "aguardAprovControla" | "aguardAprovSuperin" | "aguardGerarSap" | "aguardAprovSap" | "aprovadoSap" |
    //             "naoAprovado" | "cancelado";
    status?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;    
};

export const StatusPedido: React.FC<StatusPedidoProps> = ({ status }) => {
    const translate = useTranslate();
    const { palette } = useTheme();

    let color: ChipProps["color"];

    switch (status) {
        // case "digitado":
        // case "aguardGerarSap":            
        //     color = "info";
        //     break;
        // case "aguardAprovGO":
        // case "aguardAprovGGO":
        // case "aguardAprovSuprim":
        // case "aguardAprovGerSuprim":
        // case "aguardAprovControla":
        // case "aguardAprovSuperin":            
        // case "aguardAprovSap":                        
        //     color = "warning";
        //     break;
        // case "aprovadoSap":
        //     color = "success";
        //     break;
        // case "naoAprovado":
        //     color = "error";
        //     break;
        // case "cancelado":
        //     color = palette.mode === "dark" ? "default" : "secondary";
        //     break;
        case 1:
        case 8:            
            color = "info";
            break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:            
        case 9:                        
            color = "warning";
            break;
        case 10:
            color = "success";
            break;
        case 11:
            color = "error";
            break;
        case 12:
            color = palette.mode === "dark" ? "default" : "secondary";
            break;            
    }

    return (
        <Chip
            variant="outlined"
            size="small"
            color={color}
            label={translate(`pages.status.id.${status}`)}
        />
    );
};