import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { IResourceComponentsProps, useMany, useTranslate } from "@refinedev/core";

export const ObraList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const { data: grupoObrasData, isLoading: grupoObrasIsLoading } = useMany({
        resource: "gruposobras",
            ids: dataGridProps?.rows?.map((item: any) => item?.idGrupoObra) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const { data: responsavelAdmData, isLoading: responsavelAdmIsLoading } = useMany({
        resource: "usuarios",
            ids: dataGridProps?.rows?.map((item: any) => item?.idUsuarioResponsavelAdm) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });    

    const { data: responsavelGoData, isLoading: responsavelGoIsLoading } = useMany({
        resource: "usuarios",
            ids: dataGridProps?.rows?.map((item: any) => item?.idUsuarioResponsavelGo) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });   

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.obras.fields.id"),
                type: "number",
                minWidth: 50,
            },
            {
                field: "nomeObra",
                flex: 1,
                headerName: translate("pages.obras.fields.nomeObra"),
                minWidth: 200,
            },
            // {
            //     field: "descObra",
            //     flex: 1,
            //     headerName: translate("pages.obras.fields.descObra"),
            //     minWidth: 200,
            // },
            {
                field: "numeroInternoObra",
                flex: 1,
                headerName: translate("pages.obras.fields.numeroInternoObra"),
                minWidth: 200,
            },
            // {
            //     field: "codigoInternoObra",
            //     flex: 1,
            //     headerName: translate("pages.obras.fields.codigoInternoObra"),
            //     minWidth: 200,
            // },                        
            {
                field: "idUsuarioResponsavelAdm",
                flex: 1,
                headerName: translate(
                    "pages.obras.fields.nomeResponsavelObraAdm",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idUsuarioResponsavelAdm;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return responsavelAdmIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + responsavelAdmData?.data?.find((item) => item.id === value)
                        ?.nomeUsuario
                    );
                },                
            },              
            {
                field: "idUsuarioResponsavelGo",
                flex: 1,
                headerName: translate(
                    "pages.obras.fields.nomeResponsavelObraGo",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idUsuarioResponsavelGo;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return responsavelGoIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + responsavelGoData?.data?.find((item) => item.id === value)
                        ?.nomeUsuario
                    );
                },                
            },                 
            {
                field: "idGrupoObra",
                flex: 1,
                headerName: translate(
                    "pages.obras.fields.idGrupoObra",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idGrupoObra;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return grupoObrasIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + grupoObrasData?.data?.find((item) => item.id === value)
                        ?.nomeGrupoObra
                    );
                },                
            },            
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                            <DeleteButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                filterable:false,
            },
        ],
        [translate,responsavelAdmIsLoading],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}/>
        </List>
    );
};