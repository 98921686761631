import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    EmailField,
    DateField,
} from "@refinedev/mui";
import { DataGrid, getGridStringOperators, GridColDef } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { IResourceComponentsProps, useMany, useTranslate } from "@refinedev/core";
import { StatusUsuario } from "../../components";
import { trace } from "console";

export const UsuarioList: React.FC<
    IResourceComponentsProps
> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const { data: perfilData, isLoading: perfilIsLoading } = useMany({
        resource: "perfis",
            ids: dataGridProps?.rows?.map((item: any) => item?.idPerfil) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });    

    const { data: departamentoData, isLoading: departamentoIsLoading } = useMany({
        resource: "departamentos",
            ids: dataGridProps?.rows?.map((item: any) => item?.idDepartamento) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });       

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains' /* add more over time */ ].includes(value),
      );    
      
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.usuarios.fields.id"),
                type: "number",
                minWidth: 50,
                filterOperators,   
            },
            {
                field: "nomeUsuario",
                flex: 1,
                headerName: translate("pages.usuarios.fields.nomeUsuario"),
                minWidth: 200,
                filterOperators,   
            },
            {
                field: "emailUsuario",
                flex: 1,
                headerName: translate("pages.usuarios.fields.emailUsuario"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <EmailField value={value} />;
                },
                filterOperators,   
            },
            {
                field: "numeroTelefoneUsuario",
                flex: 1,
                headerName: translate("pages.usuarios.fields.numeroTelefoneUsuario"),
                type: "number",
                minWidth: 200,
                filterable:false,                
            },
            {
                field: "idPerfil",
                flex: 1,
                headerName: translate(
                    "pages.usuarios.fields.idPerfil",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idPerfil;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return perfilIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + perfilData?.data?.find((item) => item.id === value)
                        ?.nomePerfil
                    );
                },    
                filterOperators,               
            }, 
            {
                field: "idDepartamento",
                flex: 1,
                headerName: translate(
                    "pages.usuarios.fields.idDepartamento",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idDepartamento;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return departamentoIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + departamentoData?.data?.find((item) => item.id === value)
                        ?.nomeDepartamento
                    );
                },    
                filterOperators,               
            }, 
            {
                field: "flAtivo",
                headerName: translate("pages.usuarios.fields.flAtivo.label"),
                minWidth: 136,
                renderCell: function render({ row }) {
                  return <StatusUsuario value={row.flAtivo} />;
                },
                filterOperators,   
              },            
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                            {/* <DeleteButton hideText recordItemId={row.id} /> */}
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                filterable:false,
            },
        ],
        [translate,perfilIsLoading,departamentoIsLoading],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}/>
        </List>
    );
};
