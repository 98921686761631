const checkPermissionResource = () => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return (auth.roles === 'admin' 
            || auth.roles === 'aprovControl'
            ) ?true:false;
};

const canCreateOrder = () => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return (auth.roles === 'admin' 
            || auth.roles === 'adminObra'
            || auth.roles === 'gerenteObra'
            || auth.roles === 'coordObra'
            || auth.roles === 'aprovControl'
            ) ?true:false;
};

const canEditOrder = (idStatus:number) => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return  (
                (auth?.status_profile?.find(
                    (item:any)=>item.idStatus===idStatus
            )
            ?.acessoEdicao)
    )                   
};

const canViewOrder = (idStatus:number) => {

    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');

    return  (
                (auth?.status_profile?.find(
                    (item:any)=>item.idStatus===idStatus
            )
            ?.acessoLeitura)
    )                   
};


export{
    checkPermissionResource,
    canCreateOrder,
    canEditOrder,
    canViewOrder
}