import { useTranslate, useNavigation, useTable, useCustom, useApiUrl } from "@refinedev/core";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import dayjs from "dayjs";

import { IPedido } from "../../../interfaces";
import { useTheme } from "@mui/material/styles";


export const PedidoTimeline: React.FC = () => {
    const theme = useTheme();        

    const translate = useTranslate();
    const { show } = useNavigation();

    const apiUrl = useApiUrl();
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');
    const aliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

    const { data:dataPedidoUsuario, isLoading: pedUsuIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/usuarios/${auth.id}`,
        method: "get",
      });
    const pedidosUsuario =  dataPedidoUsuario?.data ?? [];  
    const arrayPedidosUsuario = pedidosUsuario.map((pedido: { id: any; }) => pedido.id);


    const { tableQueryResult, current, setCurrent, pageCount } =
        useTable<IPedido>({
            resource: "pedidos",
            initialSorter: [
                {
                    field: "dhInclusao",
                    order: "desc",
                },
            ],
            // initialPageSize: 5,
            pagination: {
                pageSize: 5,
              },            
            syncWithLocation: false,
            filters:{
                permanent:[
                    {
                        field:"id",
                        operator:"in",
                        value:arrayPedidosUsuario
                    }
                ]
            }
        });

    const { data } = tableQueryResult;


    const pedidoStatusColor = (
        id: string,
    ): { color: string; text: string; dotColor: string } => {
        switch (id) {
            case "1":
                return {
                    color: theme.timeLine.color.info,
                    text: "info",
                    dotColor: theme.timeLine.dotColor.info,
                };
            case "2":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };
            case "3":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };
            case "4":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };
            case "5":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };
            case "6":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };
            case "7":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };  
            case "8":
                return {
                    color: theme.timeLine.color.info,
                    text: "info",
                    dotColor: theme.timeLine.dotColor.info,
                };  
            case "9":
                return {
                    color: theme.timeLine.color.warning,
                    text: "warning",
                    dotColor: theme.timeLine.dotColor.warning,
                };                                                
            case "10":
                return {
                    color: theme.timeLine.color.success,
                    text: "success",
                    dotColor: theme.timeLine.dotColor.success,
                }; 
            case "11":
                return {
                    color: theme.timeLine.color.error,
                    text: "error",
                    dotColor: theme.timeLine.dotColor.error,
                };  
            case "12":
                return {
                    color: theme.timeLine.color.default,
                    text: "default",
                    dotColor: theme.timeLine.dotColor.default,
                };  
            default:
                return {
                    color: theme.timeLine.color.default,
                    text: "default",
                    dotColor: theme.timeLine.dotColor.default,
                };                                                   
        }
    };

    return (
        <>
            <Timeline position="right" sx={{ mt: 0, pt: 0 }}>
                {data?.data.map(({ dhInclusao, id, numeroPedido, idStatus }) => {
                    const text = pedidoStatusColor(idStatus.toString())?.text;
                    const color = pedidoStatusColor(idStatus.toString())?.color;
                    const dotColor = pedidoStatusColor(
                        idStatus.toString(),
                    )?.dotColor;

                    return (
                        <TimelineItem key={id}>
                            <TimelineOppositeContent sx={{ display: "none" }} />
                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    sx={{ borderColor: dotColor }}
                                />
                                <TimelineConnector sx={{ width: "1px" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box
                                    sx={{
                                        backgroundColor: color,
                                        borderRadius: 2,
                                        p: 1,
                                    }}
                                >
                                    <Tooltip
                                        arrow
                                        title={dayjs(dhInclusao).format("lll")}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{ fontStyle: "italic" }}
                                        >
                                            {dayjs(dhInclusao).fromNow()}
                                        </Typography>
                                    </Tooltip>
                                    <Typography variant="subtitle2">
                                        {translate(
                                            `pages.status.id.${idStatus.toString()}`,
                                        )}
                                    </Typography>
                                    <Button
                                        variant="text"
                                        onClick={() => show("pedidos", id)}
                                        size="small"
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ color: "text.primary" }}
                                        >
                                            #{numeroPedido}
                                        </Typography>
                                    </Button>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
                <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                    <Pagination
                        count={pageCount}
                        page={current}
                        onChange={(e, page) => setCurrent(page)}
                        siblingCount={0}
                        boundaryCount={0}
                        size="small"
                        color="primary"
                    />
                </Box>
            </Timeline>
        </>
    );
};