import React from "react";
import { useApiUrl, useCustom, useMany, useNavigation, useTranslate, useUpdate } from "@refinedev/core";
import { DateField, NumberField, useDataGrid } from "@refinedev/mui";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef,getGridStringOperators } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';

import { StatusPedido } from "../../statuspedido";
import { IPedido } from "../../../interfaces";

export const PedidosRecentes: React.FC = () => {
    const translate = useTranslate();
    const { edit } = useNavigation();

    const apiUrl = useApiUrl();
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');
    const aliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

    const { data:dataPedidoUsuario, isLoading: pedUsuIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/usuarios/${auth.id}`,
        method: "get",
      });
    const pedidosUsuario =  dataPedidoUsuario?.data ?? [];  
    const arrayPedidosUsuario = pedidosUsuario.map((pedido: { id: any; }) => pedido.id);    

    const { dataGridProps } = useDataGrid<IPedido>({
        resource: "pedidos",       
        permanentFilter: [
            {
                field: "idStatus",
                operator: "ne",
                value: 12,
            },
            {
                field:"id",
                operator:"in",
                value:arrayPedidosUsuario
            },            
        ],
        pagination: {
            pageSize: 5,
          },    
        sorters: {
        initial: [
            {
            field: "dhPedido",
            order: "desc",
            },
         ],
        },                         
        syncWithLocation: false,
    });

    const { data: obrasData, isLoading: obrasIsLoading } = useMany({
        resource: "obras",
            ids: dataGridProps?.rows?.map((item: any) => item?.idObra) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });       

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains' /* add more over time */ ].includes(value),
      ); 

    const columns = React.useMemo<GridColDef<IPedido>[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.pedidos.fields.id"),
                type: "number",
                minWidth: 20,
                headerAlign:"center",
                renderCell: function render({ row }) {
                    return (
                            <Button
                                variant="text"
                                onClick={() => edit("pedidos", row.id)}
                                size="small"
                                sx={{ width: 80 }}
                            >
                                <Typography
                                    sx={{
                                        color: "text.primary",
                                        fontWeight: 700,
                                    }}
                                >
                                    #{row.id}
                                </Typography>
                            </Button>
                    );
                },    
                filterOperators,               
            },
            {
                field: "numeroPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.numeroPedido"),
                type: "number",
                minWidth: 120,
                filterOperators, 
            },               
            {
                field: "numeroContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.numeroContrato"),
                type: "number",
                minWidth: 120,
                filterOperators, 
            },                             
            {
                field: "idObra",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idObra",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idObra;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return obrasIsLoading ? (
                        <>Loading...</>
                    ) : (
                        // value + ' - ' + obrasData?.data?.find((item) => item.id === value)
                        // ?.nomeObra
                        obrasData?.data?.find((item) => item.id === value)
                        ?.numeroInternoObra + ' - ' + obrasData?.data?.find((item) => item.id === value)
                        ?.nomeObra
                    );
                },    
                filterOperators,             
            },   
            {
                field: "nomeFornecedor",
                flex: 1,
                headerName: translate("pages.pedidos.fields.nomeFornecedor"),
                minWidth: 200,
                filterOperators, 
            },    
            {
                field: "valorAtualContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorAtualContrato"),
                type: "number",
                minWidth: 120,
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },   
                filterable:false,               
            }, 
            {
                field: "valorAdicional",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorAdicional"),
                type: "number",
                minWidth: 120,
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },  
                filterable:false,              
            }, 
            {
                field: "valorNovoContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorNovoContrato"),
                type: "number",
                minWidth: 120,
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },    
                filterable:false,              
            },                 
            {
                field: "dhPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.dhPedido"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={value} format="DD/MM/YYYY"/>;
                },
                filterable:false,
            },
            {
                field: "idStatus",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idStatus",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idStatus;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return <StatusPedido status={value} />
                },   
                filterable:false,             
            },
            {
                field: "observPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.observPedido"),
                minWidth: 200,
                filterable:false,
            },              
        ],
        [translate,obrasIsLoading],
    );

    return (
        <DataGrid
            {...dataGridProps}
            columns={columns}
            autoHeight
            // columnHeaderHeight={0}
            rowHeight={90}
            pageSizeOptions={[5, 10, 25]}
            sx={{
                paddingX: { xs: 3 },
                border: "none",
                "& .MuiDataGrid-row": {
                cursor: "pointer",
                },
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
    );
};